// frontend/src/components/Login.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { login } from '../api';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import '../Login.css';

const Login = () => {
  const [customerId, setCustomerId] = useState('');
  const [passCode, setPassCode] = useState('');
  const navigate = useNavigate();
  const { login: authLogin } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(customerId, passCode);
      
      // Sadece müşteri rolünü kontrol et
      if (response.user.role !== 'customer') {
        toast.error('Bu giriş sadece müşteriler içindir. Admin girişi için /admin sayfasını kullanın.');
        return;
      }

      localStorage.setItem('token', response.token);
      authLogin({
        customerId: response.user.customerId,
        role: 'customer'
      });
      
      navigate('/customer-form');
    } catch (error) {
      console.error('Login hatası:', error);
      toast.error(error.message || 'Giriş başarısız');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-wrapper">
        <img src="/assets/byte-logo.svg" alt="Byte Logo" className="logo" />
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Müşteri Girişi</h2>
          <div className="form-group">
            <label htmlFor="customerId">Müşteri ID</label>
            <input
              id="customerId"
              type="text"
              value={customerId}
              onChange={(e) => setCustomerId(e.target.value)}
              placeholder="Müşteri ID'nizi girin"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="passCode">Şifre</label>
            <input
              id="passCode"
              type="password"
              value={passCode}
              onChange={(e) => setPassCode(e.target.value)}
              placeholder="Şifrenizi girin"
              required
            />
          </div>
          <button type="submit" className="login-button">Giriş Yap</button>
        </form>
        <p className="admin-link">
          Admin girişi için <Link to="/admin">tıklayınız</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;