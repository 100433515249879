import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Link'i import edelim
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api';
import '../AdminDashboard.css';
import { useAuth } from '../context/AuthContext';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [brandName, setBrandName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get('/admin/customers');
      console.log('API response:', response.data); // API yanıtını kontrol et
      setUsers(response.data);
    } catch (error) {
      console.error('Müşteriler alınırken hata oluştu:', error);
      toast.error('Müşteriler alınamadı');
    }
  };

  const handleAddCustomer = async (e) => {
    e.preventDefault();
    try {
      await api.post('/admin/add-customer', { brandName });
      setBrandName('');
      fetchUsers();
      toast.success('Müşteri başarıyla eklendi');
    } catch (error) {
      console.error('Müşteri eklenirken hata oluştu:', error);
      toast.error('Müşteri eklenemedi');
    }
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      logout();
      navigate('/admin');
      toast.success('Başarıyla çıkış yapıldı');
    } catch (error) {
      console.error('Çıkış yapılırken hata:', error);
      toast.error('Çıkış yapılırken bir hata oluştu');
    }
  };

  const filteredUsers = users.filter(user => 
    (user.brandName && user.brandName.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (user.customerId && user.customerId.toString().toLowerCase().includes(searchTerm.toLowerCase()))
  );

  console.log('Filtered users:', filteredUsers); // Filtrelenmiş kullanıcıları kontrol et

  return (
    <>
      <div className="header-wrapper">
        <div className="container">
          <div className="header">
            <div className="header-logo">
              <img src="/assets/byte-logo.svg" alt="Byte Logo" className="logo" />
            </div>
            <button onClick={handleLogout} className="header-button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
              Çıkış Yap
            </button>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="admin-dashboard">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            icon={false}  // SVG ikonunu kaldırır
          />
          <h1>Admin Dashboard</h1>
          <form onSubmit={handleAddCustomer} className="add-customer-form">
            <input
              type="text"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              placeholder="Yeni müşteri marka adı"
              required
            />
            <button type="submit">Müşteri Ekle</button>
          </form>
          <div className="search-container">
            <input
              type="text"
              placeholder="Marka adı veya Müşteri ID'ye göre ara"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="admin-table-container">
            <table className="admin-table">
              <thead>
                <tr>
                  <th>Müşteri ID</th>
                  <th>Marka Adı</th>
                  <th>Form Durumu</th>
                  <th>Şifre (Passcode)</th>
                  <th>Form Özeti</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.customerId}>
                    <td>{user.customerId}</td>
                    <td>{user.brandName}</td>
                    <td>{user.formStatus}</td>
                    <td>{user.passCode || 'N/A'}</td>
                    <td>
                      {user.formStatus === 'completed' ? (
                        <Link to={`/form-summary/${user.customerId}`}>Form Özeti</Link>
                      ) : (
                        'Henüz tamamlanmadı'
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
