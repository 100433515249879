import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCustomerResponses } from '../api';
import './FormSummary.css';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';

const FormSummary = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { customerId } = useParams();
  const { user } = useAuth();

  const handleBackToAdmin = () => {
    navigate('/admin-dashboard');
  };

  useEffect(() => {
    // Kullanıcı kontrolü
    if (!user) {
      navigate('/login');
      return;
    }

    // Admin değilse ve kendi form özeti değilse erişimi engelle
    if (user.role !== 'admin' && user.customerId !== customerId) {
      toast.error('Bu sayfaya erişim yetkiniz yok');
      navigate('/customer-form');
      return;
    }

    const fetchFormData = async () => {
      try {
        const response = await getCustomerResponses(customerId);
        console.log('Form verileri:', response); // Gelen veriyi kontrol et
        setFormData(response.formData);
        setLoading(false);
      } catch (err) {
        console.error('Veri çekme hatası:', err);
        setError('Form verileri alınırken bir hata oluştu.');
        setLoading(false);
      }
    };

    fetchFormData();
  }, [customerId, user, navigate]);

  if (loading) return <div className="loading">Yükleniyor...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!formData) return <div className="error">Form verisi bulunamadı.</div>;

  // Form alanlarını kategorilere ayır
  const sections = {
    'Kişisel Bilgiler': {
      name: 'İsim',
      email: 'E-posta',
      phoneNumber: 'Telefon',
      companyName: 'Şirket Adı',
      pocName: 'İletişim Kişisi Adı',
      pocEmail: 'İletişim Kişisi E-posta',
      pocPhone: 'İletişim Kişisi Telefon'
    },
    'Proje Detayları': {
      storeName: 'Mağaza Adı',
      storePhone: 'Mağaza Telefonu',
      storeEmail: 'Mağaza E-postası',
      billingAddress: 'Fatura Adresi',
      billingTaxInfo: 'Vergi Bilgileri',
      storeCurrency: 'Mağaza Para Birimi',
      shippingRates: 'Kargo Ücretleri',
      taxes: 'Vergiler',
      senderEmail: 'Gönderen E-posta',
      languages: 'Diller',
      policies: 'Politika Dosyaları'
    },
    'Tasarım Detayları': {
      generalDesignBrief: 'Genel Tasarım Açıklaması',
      headerDesignBrief: 'Header Tasarım Açıklaması',
      headerDesignScreenshots: 'Header Tasarım Ekran Görüntüleri',
      footerDesignBrief: 'Footer Tasarım Açıklaması',
      footerDesignScreenshots: 'Footer Tasarım Ekran Görüntüleri',
      homepageDesignBrief: 'Anasayfa Tasarım Açıklaması',
      homepageDesignScreenshots: 'Anasayfa Tasarım Ekran Görüntüleri',
      collectionPageDesignBrief: 'Koleksiyon Sayfası Tasarım Açıklaması',
      collectionPageScreenshots: 'Koleksiyon Sayfası Ekran Görüntüleri',
      productPageDesignBrief: 'Ürün Sayfası Tasarım Açıklaması',
      productPageScreenshots: 'Ürün Sayfası Ekran Görüntüleri',
      otherPagesDesignBrief: 'Diğer Sayfalar Tasarım Açıklaması',
      otherPagesScreenshots: 'Diğer Sayfalar Ekran Görüntüleri'
    },
    'Entegrasyonlar': {
      marketplaceIntegrations: 'Pazar Yeri Entegrasyonları',
      erpIntegrations: 'ERP Entegrasyonları',
      paymentInfo: 'Ödeme Entegrasyonu',
      shippingInfo: 'Kargo Entegrasyonu',
      invoicingInfo: 'Fatura Entegrasyonu',
      selectedIntegrations: 'Kullanmak İstediğiniz Eklentiler'
    }
  };

  const renderValue = (key, value) => {
    // Eğer dosya alanıysa ve array ise
    if (Array.isArray(value) && 
           value.length > 0 && 
           value[0] && 
           typeof value[0] === 'object' && 
           'url' in value[0] && 
           'originalName' in value[0]) {
      return (
        <ul className="file-list">
          {value.map((file, index) => (
            <li key={index}>
              <a href={file.url} target="_blank" rel="noopener noreferrer" className="file-link">
                <div className="file-info">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" className="file-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"></path>
                  </svg>
                  <span className="file-name">{file.originalName}</span>
                </div>
              </a>
            </li>
          ))}
        </ul>
      );
    }

    // Normal alanlar için
    return Array.isArray(value) ? value.join(', ') : value;
  };

  return (
    <div className="form-summary-container">
      {/* Admin için geri dönüş butonu */}
      {user?.role === 'admin' && (
        <button 
          className="admin-back-button"
          onClick={handleBackToAdmin}
        >
          ← Admin Panele Dön
        </button>
      )}

      <header className="summary-header">
        <img src="/assets/byte-logo.svg" alt="Byte Logo" className="logo" />
        <h1>Form Özeti</h1>
        <p className="customer-id">Müşteri ID: {customerId}</p>
      </header>

      <div className="summary-content">
        {Object.entries(sections).map(([sectionTitle, fields]) => (
          <section key={sectionTitle} className="summary-section">
            <h2>{sectionTitle}</h2>
            <div className="fields-grid">
              {Object.entries(fields).map(([key, label]) => (
                formData[key] && (
                  <div key={key} className="field-item">
                    <label>{label}:</label>
                    <div className="field-value">
                      {renderValue(key, formData[key])}
                    </div>
                  </div>
                )
              ))}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default FormSummary;