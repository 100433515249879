import React from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import api from '../api';

const Dropzone = ({ customerId, fieldName, setFieldValue, values }) => {
  const handleUpload = async (acceptedFiles) => {
    try {
      console.log('Upload başlıyor:', {
        customerId,
        fieldName,
        filesCount: acceptedFiles.length
      });

      const formData = new FormData();
      
      acceptedFiles.forEach(file => {
        const normalizedFileName = file.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[ğ]/g, 'g')
          .replace(/[Ğ]/g, 'G')
          .replace(/[ı]/g, 'i')
          .replace(/[İ]/g, 'I')
          .replace(/[ö]/g, 'o')
          .replace(/[Ö]/g, 'O')
          .replace(/[ü]/g, 'u')
          .replace(/[Ü]/g, 'U')
          .replace(/[ş]/g, 's')
          .replace(/[Ş]/g, 'S')
          .replace(/[ç]/g, 'c')
          .replace(/[Ç]/g, 'C')
          .replace(/\s+/g, '-');
        
        const renamedFile = new File([file], normalizedFileName, { type: file.type });
        formData.append('files', renamedFile);
      });
      formData.append('customerId', customerId);
      formData.append('fieldName', fieldName);

      const response = await api.post(`/upload/${customerId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });


      if (response.data.files) {
        const currentFiles = values[fieldName] || [];
        const newFiles = [...currentFiles, ...response.data.files];
        setFieldValue(fieldName, newFiles);
        
        // Dosya yüklendikten sonra otomatik kaydet
        await api.post('/customer/save-form', {
          customerId,
          formData: values,
          lastStepCompleted: 3 // DesignDetails adımı
        });
        
        toast.success('Dosyalar yüklendi ve form kaydedildi');
      }

      console.log('Upload tamamlandı:', {
        fieldName,
        response: response.data
      });
    } catch (error) {
      console.error('Upload hatası:', error);
      toast.error('Dosya yüklenirken bir hata oluştu');
    }
  };

  const handleDelete = async (fileToDelete, index) => {
    try {
      await api.delete(`/upload/delete`, {
        data: {
          customerId,
          fieldName,
          filename: fileToDelete.filename
        }
      });

      const currentFiles = values[fieldName] || [];
      const newFiles = currentFiles.filter((_, i) => i !== index);
      setFieldValue(fieldName, newFiles);
      toast.success('Dosya başarıyla silindi');
    } catch (error) {
      console.error('Dosya silme hatası:', error);
      toast.error('Dosya silinirken bir hata oluştu');
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop: handleUpload,
    maxSize: 25 * 1024 * 1024, // 25MB limit
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach(rejection => {
        if (rejection.errors[0]?.code === 'file-too-large') {
          toast.error(`${rejection.file.name} dosyası 25MB'dan büyük!`);
        } else if (rejection.errors[0]?.code === 'file-invalid-type') {
          toast.error(`${rejection.file.name} desteklenmeyen bir dosya türü!`);
        } else {
          toast.error(`${rejection.file.name} yüklenemedi: ${rejection.errors[0]?.message}`);
        }
      });
    }
  });

  const renderFiles = () => {
    const files = values[fieldName] || [];
    return files.map((file, index) => (
      <div key={index} className="uploaded-file">
        <a href={file.url} target="_blank" rel="noopener noreferrer" className="file-info">
          <span className="file-name">{file.originalName}</span>
        </a>
        <button onClick={() => handleDelete(file, index)} className="file-remove">x</button>
      </div>
    ));
  };

  return (
    <div>
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'drag-active' : ''}`}>
        <input {...getInputProps()} />
        <div className="dropzone-content">
          <p className="dropzone-text">
            Dosyaları sürükleyip bırakın veya seçmek için tıklayın
          </p>
          <p className="dropzone-info">
            Desteklenen formatlar: PDF, Word, Excel, JPG, PNG
            <br />
            Maksimum dosya boyutu: 25MB
            <br />
            En fazla 8 dosya yükleyebilirsiniz
          </p>
        </div>
      </div>
      
      <div className="file-list">
        {renderFiles()}
      </div>
    </div>
  );
};

export default Dropzone;
