import React, { useState } from 'react';

const plugins = [
  {
    name: 'Wishlist',
    description: 'Müşterilerin ürünleri favori listelerine kaydetmelerine ve bu liste üzerinden satın almalarına olanak tanır.',
    price: 'Ortalama $5-10 / Ay'
  },
  {
    name: 'Breadcrumbs',
    description: "Ürün ve kategori hiyerarşilerini yönetmenizi sağlar. Shopify'da sunulan varsayılan Breadcrumbs yerine, SEO'ya daha uygun özel bir hiyerarşi kullanmanıza imkan tanır.",
    price: 'Ortalama $5-10 / Ay'
  },
  {
    name: 'Ürün Yorumları',
    description: 'Müşterilerin ürünlere yorum yapmalarını sağlar. Yapılan yorumlar ürün sayfasında, ürün kartlarında yıldız olarak ve ana sayfada görüntülenebilir.',
    price: 'Ortalama $0-10 / Ay'
  },
  {
    name: 'Toplu Ürün Düzenleme',
    description: "Shopify'ın varsayılan ürün yükleme şablonundan bağımsız olarak, yalnızca ihtiyacınız olan alanlarla daha sade bir dosya indirmenize ve kendi Excel şablonunuzu yüklemenize imkan veren eklentilerdir.",
    price: 'Ortalama $20-30 / Ay'
  },
  {
    name: 'XML Entegrasyonu',
    description: 'XML ile düzenli olarak sitenize ürün eklemek ve stokları güncellemek için kullanabileceğiniz eklentilerdir.',
    price: 'Ortalama $20 / Ay'
  },
  {
    name: 'Özel XML Çıktısı',
    description: 'Farklı satış kanalları ve Google, Facebook gibi pazarlama mecraları için özel yapılandırılmış, otomatik olarak güncellenen XML dosyaları hazırlamanıza olanak tanır.',
    price: 'Ortalama $10-20 / Ay'
  },
  {
    name: 'Omnisend',
    description: "Shopify'ın standart e-posta sisteminden daha gelişmiş e-posta pazarlama ihtiyaçlarınız için kullanılabilir.",
    price: 'Müşteri Kitlesenin Büyüklüğüne Göre Değişir'
  }
];

const recommendedPlugins = ['Wishlist', 'Breadcrumbs'];

const Integrations = ({ handleInputChange, values }) => {
  const [selectedApps, setSelectedApps] = useState(values.selectedIntegrations || []);

  const handleAppSelection = (app) => {
    let updatedApps;
    
    if (selectedApps.includes(app.name)) {
      updatedApps = selectedApps.filter(appName => appName !== app.name);
    } else {
      updatedApps = [...selectedApps, app.name];
    }
    
    setSelectedApps(updatedApps);

    handleInputChange({
      target: {
        name: 'selectedIntegrations',
        value: updatedApps
      }
    });
  };

  return (
    <div className="integrations-container">
      <h2 className="integrations-title">Entegrasyonlar</h2>
      
      <div className="integrations-section">
        <label className="integrations-label" htmlFor="marketplaceIntegrations">
          Pazar Yeri Entegrasyonları:
        </label>
        <span className="design-details-info">Varsa talep edilen pazaryeri entegrasyonlarınızı belirtiniz. Kullanmak istediğiniz entegratör var ise veya entegratör kullanmak istemiyorsanız ayrıca belirtiniz.</span>
        <input
          className="integrations-input"
          type="text"
          id="marketplaceIntegrations"
          name="marketplaceIntegrations"
          value={values.marketplaceIntegrations}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-section">
        <label className="integrations-label" htmlFor="erpIntegrations">
          ERP Entegrasyonları:
        </label>
        <span className="design-details-info">ERP entegrasyonu talep ediyorsanız entegratörünüzü belirtiniz. Kullanmak istediğiniz entegratör var ise veya entegratör kullanmak istemiyorsanız ayrıca belirtiniz.</span>
        <input
          className="integrations-input"
          type="text"
          id="erpIntegrations"
          name="erpIntegrations"
          value={values.erpIntegrations}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-section">
        <label className="integrations-label" htmlFor="paymentInfo">
          Ödeme Entegrasyonu:
        </label>
        <span className="design-details-info">Hangi ödeme yöntemlerini kullanmak istediğinizi belirtiniz. Klon site talep ediliyorsa her site için ayrı ayrı belirtiniz. Havale gibi manuel ödeme yöntemleri için ayrıca hesap bilgilerinizi belirtiniz.</span>
        <textarea
          className="integrations-textarea"
          id="paymentInfo"
          name="paymentInfo"
          value={values.paymentInfo}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-section">
        <label className="integrations-label" htmlFor="shippingInfo">
          Kargo Entegrasyonu:
        </label>
        <span className="design-details-info">Varsa talep edilen kargo entegrasyonlarınızı belirtiniz. Kullanmak istediğiniz entegratör var ise veya entegratör kullanmak istemiyorsanız ayrıca belirtiniz.</span>
        <textarea
          className="integrations-textarea"
          id="shippingInfo"
          name="shippingInfo"
          value={values.shippingInfo}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-section">
        <label className="integrations-label" htmlFor="invoicingInfo">
          Fatura Entegrasyonu:
        </label>
        <span className="design-details-info">Varsa talep edilen fatura entegrasyonlarınızı belirtiniz. Kullanmak istediğiniz entegratör var ise veya entegratör kullanmak istemiyorsanız ayrıca belirtiniz.</span>
        <textarea
          className="integrations-textarea"
          id="invoicingInfo"
          name="invoicingInfo"
          value={values.invoicingInfo}
          onChange={handleInputChange}
        />
      </div>

      <div className="integrations-plugins">
        <h3 className="integrations-subtitle integrations-label">Kullanmak İstediğiniz Eklentiler:</h3>
        <span className="design-details-info">Eklentileri seçerek kullanmak istediğiniz eklentileri belirtiniz. Seçilen eklentilerin kurulumu Byte tarafından teklif içerisnde yapılacaktır ancak uygulamaların ücretleri her ay Shopify tarafından faturanıza yansıtılacaktır.</span>
        <div className="integrations-plugins-grid">
          {plugins.map((plugin) => (
            <div 
              key={plugin.name} 
              className={`integrations-plugin-item ${selectedApps.includes(plugin.name) ? 'selected' : ''}`}
              onClick={() => handleAppSelection(plugin)}
            >
              <div className="integrations-plugin-checkbox">
                <input
                  type="checkbox"
                  checked={selectedApps.includes(plugin.name)}
                  onChange={() => {}} 
                  onClick={(e) => e.stopPropagation()} 
                />
                <label className="integrations-plugin-label">{plugin.name}</label>
                {recommendedPlugins.includes(plugin.name) && (
                  <span className="recommended-badge">Önerilir</span>
                )}
              </div>
              <p className="integrations-plugin-description">{plugin.description}</p>
              <span className="integrations-plugin-price">{plugin.price}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
