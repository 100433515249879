// frontend/src/App.js

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import CustomerForm from './components/CustomerForm';
import AdminDashboard from './components/AdminDashboard';
import FormSummary from './components/FormSummary'; // Bu bileşeni oluşturacağız
import { AuthProvider } from './context/AuthContext'; // 'contexts' yerine 'context'
import PrivateRoute from './components/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLogin from './components/AdminLogin';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <AuthProvider>
      <Helmet>
        <title>Byte Onboarding | Müşteri Talep Toplama Sistemi</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Byte Onboarding, müşterilerimizin taleplerini toplamak ve proje kapsamında gerekli bilgileri almak amacıyla kullanılan gelişmiş bir talep formudur." />
        <link rel="icon" type="image/png" href="/assets/byte-favicon.png" />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route 
            path="/customer-form" 
            element={
              <PrivateRoute requiredRole="customer">
                <CustomerForm />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/admin-dashboard" 
            element={
              <PrivateRoute requiredRole="admin">
                <AdminDashboard />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/form-summary/:customerId" 
            element={
              <PrivateRoute>
                <FormSummary />
              </PrivateRoute>
            } 
          />
          <Route path="/" element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;