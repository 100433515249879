// frontend/src/components/formSteps/ProjectDetails.js

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Dropzone from '../Dropzone';
import { toast } from 'react-toastify';

/* eslint-disable no-unused-vars */
const ProjectDetails = ({ handleInputChange, values, setFieldValue, setFormData, nextStep, customerId }) => {
  const handleNext = () => {
    const requiredFields = [
      'storeName', 
      'storePhone', 
      'storeEmail',
      'billingAddress',
      'billingTaxInfo',
      'storeCurrency',
      'shippingRates',
      'taxes',
      'senderEmail',
      'languages'
    ];

    const missingFields = requiredFields.filter(field => !values[field]);

    if (missingFields.length > 0) {
      toast.error('Lütfen tüm gerekli alanları doldurun: ' + missingFields.join(', '));
      highlightMissingFields(missingFields);
      return;
    }

    nextStep();
  };

  const highlightMissingFields = (fields) => {
    fields.forEach(field => {
      const element = document.getElementsByName(field)[0];
      if (element) {
        element.classList.add('highlight');
      }
    });
  };

  const removeHighlight = (field) => {
    const element = document.getElementsByName(field)[0];
    if (element) {
      element.classList.remove('highlight');
    }
  };

  const handleStorePhoneChange = (value, data) => {
    handleInputChange({
      target: {
        name: 'storePhone',
        value: `+${data.dialCode}${value.slice(data.dialCode.length)}`
      }
    });
  };

  return (
    <div className="project-details-container">
      <h2 className="project-details-title">Proje Detayları</h2>
      
      <div className="project-details-section">
        <label className="project-details-label" htmlFor="storeName">Mağaza Adı: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Marka adınızı sitede gösterilecek formatta yazınız.</span>
        <input
          className="project-details-input"
          type="text"
          id="storeName"
          name="storeName"
          value={values.storeName}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="storePhone">Mağaza Telefonu: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Markanızın iletişim numarasını yazınız.</span>
        <div className="project-details-phone">
          <PhoneInput
            country={'tr'}
            value={values.storePhone || ''}
            onChange={handleStorePhoneChange}
            inputProps={{
              name: 'storePhone',
              required: true,
              className: 'project-details-phone-input'
            }}
          />
        </div>
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="storeEmail">Mağaza Admin E-posta: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Mağazaya dair e-postaların geleceği e-posta adresini yazınız. Müşterilere giden e-postalar için ayrı bir e-posta adresi Gönderici E-posta alanından tanımlanabilir.</span>
        <input
          className="project-details-input"
          type="email"
          id="storeEmail"
          name="storeEmail"
          value={values.storeEmail}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="billingAddress">Fatura Adresi: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Firmanızın fatura adresini yazınız. Bu adres Shopify'da fatura bilgileri için kullanılacaktır.</span>
        <input
          className="project-details-input"
          type="text"
          id="billingAddress"
          name="billingAddress"
          value={values.billingAddress}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="billingTaxInfo">Fatura Bilgileri: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Firmanızın fatura bilgilerini yazınız. Vergi numarası, vergi dairesi gibi bilgileri giriniz.</span>
        <input
          className="project-details-input"
          type="text"
          id="billingTaxInfo"
          name="billingTaxInfo"
          value={values.billingTaxInfo}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="storeCurrency">Mağaza Para Birimi: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Mağazada satış yapılacak ana para birimini seçiniz. Örn: USD, EUR, GBP, TRY. İkinci bir mağaza para birimi varsa bunu da belirtiniz.</span>
        <input
          className="project-details-input"
          type="text"
          id="storeCurrency"
          name="storeCurrency"
          value={values.storeCurrency}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="shippingRates">Kargo Fiyatları: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Kargo fiyat baremleri ve varsa bölgelere göre fiyatları belirtiniz.</span>
        <textarea
          className="project-details-textarea"
          id="shippingRates"
          name="shippingRates"
          value={values.shippingRates}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="taxes">Vergiler: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Site geneli ürünleriniz için vergi oranını belirtiniz. Genel vergi oranına istisna ürünler varsa bu vergi oranlarınıbelirtiniz.</span>
        <input
          className="project-details-input"
          type="text"
          id="taxes"
          name="taxes"
          value={values.taxes}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="senderEmail">Gönderici E-posta: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Mağazadan müşterilere e-posta gönderilirken kullanılacak e-posta adresini giriniz.</span>
        <input
          className="project-details-input"
          type="email"
          id="senderEmail"
          name="senderEmail"
          value={values.senderEmail}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="languages">Diller: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Sitenin ana dilini seçiniz ve varsa ek dilleri ekleyiniz. Eğer ikinci bir klon site olacaksa bu site için de dilleri ayrıca belirtiniz.</span>
        <input
          className="project-details-input"
          type="text"
          id="languages"
          name="languages"
          value={values.languages}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="policies">Politikalar:</label>
        <span className="project-details-info">Varsa satış, iade, gizlilik ve diğer sözleşmelerinizi yükleyiniz. Eğer dosyalarınız hazır değilse bu alanı boş bırakabilirsiniz. İlerleyen süreçte proje yöneticinize iletebilirsiniz.</span>
        <div className="project-details-dropzone">
          <Dropzone 
            fieldName="policies" 
            customerId={customerId} 
            setFieldValue={setFieldValue}
            values={values}
          />
        </div>
      </div>

    </div>
  );
};
/* eslint-enable no-unused-vars */

export default ProjectDetails;
