// frontend/src/api.js

import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? '/api' 
  : 'http://localhost:3001/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Token'ı her istekte otomatik olarak ekleyen interceptor
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export const login = async (customerId, passCode) => {
  try {
    const response = await api.post('/auth/login', {
      customerId,
      passCode
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Giriş başarısız');
  }
};

export const addCustomer = async (customerData) => {
  try {
    const response = await api.post('/admin/add-customer', customerData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveForm = async (customerId, lastStepCompleted, formData) => {
  try {
    const response = await api.post('/customer/save-form', { 
      customerId, 
      lastStepCompleted, 
      formData
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const getCustomerResponses = async (customerId) => {
  try {
    const response = await api.get(`/customer/${customerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllResponses = async () => {
  try {
    const response = await api.get('/admin/responses');
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Bilinmeyen bir hata oluştu');
  }
};

export const getAllUsers = async () => {
  try {
    const response = await api.get('/admin/users');
    return response.data;
  } catch (error) {
    throw new Error('Kullanıcılar alınamadı');
  }
};

export const getCustomers = async () => {
  try {
    const response = await api.get('/admin/customers');
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Bilinmeyen bir hata oluştu');
  }
};

export const adminLogin = async (username, password) => {
  try {
    const response = await api.post('/auth/admin/login', {
      username,
      password
    });
    
    if (response.data && response.data.token) {
      return response.data;
    }
    
    throw new Error('Giriş başarısız');
  } catch (error) {
    if (error.response?.status === 401) {
      throw new Error('Geçersiz kullanıcı adı veya şifre');
    }
    throw error;
  }
};

export const completeForm = async (customerId, formData) => {
  try {
    const response = await api.post(`/customer/complete-form/${customerId}`, {
      formData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default api;