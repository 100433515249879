import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { adminLogin } from '../api';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import '../Login.css';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login: authLogin } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await adminLogin(username, password);
      
      if (response.token) {
        localStorage.setItem('token', response.token);
        authLogin({
          username: response.user.username,
          role: 'admin'
        });
        
        toast.success('Giriş başarılı!');
        navigate('/admin-dashboard');
      }
    } catch (error) {
      console.error('Login hatası:', error);
      toast.error(error.message || 'Giriş başarısız');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-wrapper">
        <img src="/assets/byte-logo.svg" alt="Byte Logo" className="logo" />
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Admin Girişi</h2>
          <div className="form-group">
            <label htmlFor="username">Kullanıcı Adı</label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Kullanıcı adınızı girin"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Şifre</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Şifrenizi girin"
              required
            />
          </div>
          <button type="submit" className="login-button">Giriş Yap</button>
        </form>
        <p className="customer-link">
          Müşteri girişi için <Link to="/login">tıklayınız</Link>
        </p>
      </div>
    </div>
  );
};

export default AdminLogin;
